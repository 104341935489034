import * as imageClientAPI from '@wix/image-client-api/dist/imageClientSDK';
import {
  COVER_MAX_SIZE,
  isValidAlbumForEnablingStore,
} from '../config/constants';

const TRANSPARENT_IMAGE_URL =
  'https://static.wixstatic.com/media/60cca8_c6fad025c40a4acb840f458b3b6f5f5b~mv2.png/v1/fit/w_297,h_206/60cca8_c6fad025c40a4acb840f458b3b6f5f5b~mv2.png';

class ImageClientApiService {
  DEFAULT_WATERMARK_DATA = {
    position: 9,
    size: 40,
    opacity: 40,
    imageUrl: '8bb438_9ad16b69fd1b4ab29324f97f1323b05a~mv2.png',
    imageRatio: 1.42517815,
  };

  getWatermarkStr = (watermark) => {
    return `${watermark.imageUrl}-${watermark.opacity}-${watermark.position}-${watermark.size}`;
  };

  getImageItemUrl = (item, { targetWidth, targetHeight } = {}) => {
    let options = {};
    if (item.photoMetadata.focalPoint) {
      options = {
        focalPoint: {
          x: (item.photoMetadata.focalPoint.x || 0.5) * 100,
          y: (item.photoMetadata.focalPoint.y || 0.5) * 100,
        },
      };
    }
    return item
      ? imageClientAPI.getScaleToFillImageURL(
        `media/${item.name}`,
        item.photoMetadata.width,
        item.photoMetadata.height,
        targetWidth || item.photoMetadata.width,
        targetHeight || item.photoMetadata.height,
        options,
      )
      : TRANSPARENT_IMAGE_URL;
  };

  formatImageUrlToCodeFormat = (item, watermarkStr) => {
    const itemWidth = item.photoMetadata ? item.photoMetadata.width : 0;
    const itemHeight = item.photoMetadata ? item.photoMetadata.height : 0;
    return (
      `wix:image://v1/${item.name}/${item.name}#originWidth=${itemWidth}&originHeight=${itemHeight}` +
      (watermarkStr ? `&watermark=${watermarkStr}` : '')
    );
  };

  getWixCodeImageWithWatermark = (item, watermark) => {
    let watermarkStr;
    if (watermark) {
      watermarkStr = this.getWatermarkStr(watermark);
    }
    return this.formatImageUrlToCodeFormat(item, watermarkStr);
  };

  getImageItemUrlWithWatermark = (item, watermark, maxSize) => {
    const { targetWidth, targetHeight } = getImageMaxDimensions(item, maxSize);
    let itemUrl = this.getImageItemUrl(item, { targetWidth, targetHeight });
    itemUrl = itemUrl.substr(0, itemUrl.lastIndexOf(".")) + ".webp";
    if (item && item.isSecure && itemUrl) {
      const token = item.imageToken || item.token;
      if (token) {
        return itemUrl + '?token=' + token;
      }
      return itemUrl.replace(
        /\/([^\/]*)$/,
        ',wm_' +
        this.getWatermarkStr(watermark || this.DEFAULT_WATERMARK_DATA) +
        '/$1',
      );
    }
    return itemUrl;
  };

  getCoverUrl = (albumData, { cover, maxSize }) => {
    if (!isValidAlbumForEnablingStore(albumData)) {
      cover.isSecure = false;
      return (
        this.getImageItemUrlWithWatermark(
          cover,
          albumData.watermark,
          maxSize,
        ) || cover.mediaUrl
      );
    } else if (cover && cover.photoMetadata) {
      return (
        this.getImageItemUrlWithWatermark(
          cover,
          albumData.watermark,
          maxSize,
        ) || cover.mediaUrl
      );
    } else if (cover) {
      return cover.thumbnail;
    } else {
      return TRANSPARENT_IMAGE_URL;
    }
  };
}

export const getImageMaxDimensions = (image, maxSize) => {
  const MAX_SIZE = (maxSize && Math.max(maxSize, 1000)) || COVER_MAX_SIZE;
  const ratio = image.photoMetadata
    ? image.photoMetadata.width / image.photoMetadata.height
    : 0;
  let targetWidth, targetHeight;
  if (ratio < 1) {
    targetHeight = MAX_SIZE;
    targetWidth = Math.floor(MAX_SIZE / ratio);
  } else {
    targetHeight = Math.floor(MAX_SIZE / ratio);
    targetWidth = MAX_SIZE;
  }

  return { targetWidth, targetHeight };
};

export default new ImageClientApiService();
