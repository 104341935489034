import _ from 'lodash';

export const EXPERIMENTS_SCOPE = 'albums'; // TODO: Change me!!!
export const TEMPLATE_MOTHER_SITE_METASITEID =
  '47f6cdef-1fd5-4527-a917-181a71465eac';
export const TRANSPARENT_IMAGE_URL =
  'a9ff3b_a268304dd2124c829801c489448477a0~mv2.png';
export const TRANSPARENT_IMAGE_URL_WIDTH = 104;
export const TRANSPARENT_IMAGE_URL_HEIGHT = 48;
export const COVER_MAX_SIZE = 500;

export const isValidAlbumForEnablingStore = (albumData) => {
  const { template, oldVersionTemplate = {} } = albumData;
  return (
    !albumData.migratedFrom &&
    template &&
    template.version > 2 &&
    (_.isEmpty(oldVersionTemplate) ||
      (oldVersionTemplate.version && oldVersionTemplate.version > 2))
  );
};
