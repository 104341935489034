export default {
  albumData: {
    id: 'ad2365fe-88c2-4ff3-94a1-75d4db2c2b96',
    brand: {
      logo: {
        name: '60cca8_4dc954ac18d84e45b3b30cf95735edb5~mv2.jpg',
        title: 'matt-lee-18767.jpg',
        mediaUrl: 's',
        photoMetadata: {
          width: 3888,
          height: 2592,
        },
      },
      name: 'Smith Photography',
      type: 'NAME',
    },
    cover: {
      id: '4f48006e-1467-42eb-9594-05e673e6d4fc',
      name: '8bb438_4ec1bb2c64774f99876607dd105a90e3~mv2_d_3456_5184_s_4_2.jpg',
      title: 'casey-horner-_4XcZQ-2G5Q-unsplash',
      created: '2020-09-08T13:53:31.364Z',
      visible: true,
      dataType: 'Photo',
      isSecure: false,
      mediaUrl:
        '8bb438_4ec1bb2c64774f99876607dd105a90e3~mv2_d_3456_5184_s_4_2.jpg',
      modified: '2020-09-08T13:53:31.364Z',
      thumbnail: '',
      imageToken: '',
      mediaOwner: 'Wix',
      orderIndex: 1599573209657,
      photoMetadata: {
        width: 3456,
        height: 5184,
        focalPoint: {
          x: 0.5,
          y: 0.5,
        },
      },
    },
    colors: {
      pageTexts: '#121212',
      coverTexts: '#FFFFFF',
      galleriesTexts: '#FFFFFF',
      pageBackground: '#FFFFFF',
      coverBackground: '#000000',
      galleriesOverlay: '#000000',
    },
    folder: {
      id: '7f348936e8664e98b4cfdd09054a6203',
      name: 'Tals album',
    },
    layout: 'collage',
    siteId: 'b68f27c6-47da-447c-835e-b32d31061e78',
    userId: '60cca876-a865-457d-b852-96265a773c22',
    version: 2,
    welcome: 'View Photos',
    allowSEO: true,
    siteName: 'tals-album',
    template: {
      name: 'classic2',
      title: 'Albums_Wizard_StylePage_Classic',
      colors: {
        pageTexts: '#121212',
        coverTexts: '#FFFFFF',
        galleriesTexts: '#FFFFFF',
        pageBackground: '#FFFFFF',
        coverBackground: '#000000',
        galleriesOverlay: '#000000',
      },
      siteId: '07994d96-7ad9-48d2-9a28-f6ffd8b51e5d',
      version: 3,
      metasiteId: '93f87473-1865-4405-a7da-de2b6c6053bd',
      selectedFontSet: 10,
      coverIsBackground: true,
    },
    albumDate: '01/09/2020',
    albumName: 'Demo Album',
    albumPass: '',
    homeSetId: '9c4b2cdd-9a60-4aa8-950c-44f953b53136',
    publicUrl: 'https://dorr42.wixsite.com/tals-album',
    watermark: {
      size: 10,
      opacity: 1,
      imageUrl: '8bb438_39a78b46d4fe47069da63da9348b4ee5~mv2.png',
      position: 1,
      imageRatio: 1,
    },
    accessType: 'PUBLIC',
    metaSiteId: '69603c14-18b3-4543-9f7c-0d0c6293a321',
    modifiedAt: 1599829649357,
    dateCreated: 1599573208762,
    displayDate: true,
    pricelistId: 'da5cb7c0-59cf-45c9-90c0-4862e2d51622',
    setsSummary: {
      '3bffad92-1ed6-4468-81e3-8be61b84a549': {
        id: '3bffad92-1ed6-4468-81e3-8be61b84a549',
        name: 'Gallery',
        items: [],
        orderIndex: 1599573209104,
      },
      'd23a49cd-aafa-4481-9e4b-49b6e071be68': {
        id: 'd23a49cd-aafa-4481-9e4b-49b6e071be68',
        name: 'Gallery 3',
        items: [],
        orderIndex: 1599573528753,
      },
      'f1e97c3b-9d42-47a9-8c3a-6411c295eaa1': {
        id: 'f1e97c3b-9d42-47a9-8c3a-6411c295eaa1',
        name: 'Gallery 2',
        items: [],
        orderIndex: 1599573255302,
      },
    },
    styleParams: {
      usm_a: 0,
      usm_r: 0,
      usm_t: 0,
      isGrid: true,
      oneRow: false,
      cubeType: 'fill',
      isSlider: false,
      viewMode: 'preview',
      boxShadow: 0,
      cubeRatio: 1,
      gridStyle: 1,
      groupSize: 1,
      isColumns: false,
      isMasonry: false,
      itemClick: 'expand',
      smartCrop: false,
      usmToggle: false,
      videoLoop: true,
      videoPlay: 'hover',
      allowHover: true,
      allowTitle: false,
      cubeImages: true,
      fullscreen: true,
      groupTypes: '1',
      isVertical: true,
      loveButton: true,
      showArrows: false,
      videoSound: false,
      videoSpeed: '1',
      allowSocial: false,
      arrowsColor: {
        value: '#FFFFFF',
      },
      gallerySize: 70,
      galleryType: 'Columns',
      imageMargin: 30,
      imageResize: 0,
      isSlideshow: false,
      itemOpacity: {
        value: 'rgba(41, 41, 41, 0.6)',
      },
      loveCounter: true,
      minItemSize: 50,
      sharpParams: {
        usm: {},
        quality: 90,
      },
      borderRadius: 0,
      cropOnlyFill: false,
      enableScroll: true,
      fixedColumns: 0,
      imageQuality: 90,
      overlayColor: {
        value: '#000000',
      },
      previewHover: false,
      useWatermark: true,
      allowDownload: false,
      galleryLayout: 2,
      hasThumbnails: false,
      imageInfoType: 'NO_BACKGROUND',
      itemFontColor: {
        value: '#FFFFFF',
      },
      itemIconColor: {
        value: '#FFFFFF',
      },
      slideshowLoop: false,
      textBoxHeight: 200,
      thumbnailSize: 120,
      watermarkDock: {
        top: 'auto',
        left: 'auto',
        right: 0,
        bottom: 0,
        transform: 'translate3d(0,0,0)',
      },
      watermarkSize: 40,
      arrowsPosition: 0,
      collageDensity: 0.4,
      floatingImages: 0,
      fullscreenLoop: false,
      gotStyleParams: true,
      groupsPerStrip: 0,
      itemShadowBlur: 20,
      itemShadowSize: 10,
      loadMoreAmount: 'all',
      mobilePanorama: false,
      overlayOpacity: 0.5,
      placeGroupsLtr: true,
      textImageSpace: 10,
      titlePlacement: 'SHOW_ON_HOVER',
      allowLinkExpand: false,
      alwaysShowHover: false,
      chooseBestGroup: true,
      expandAnimation: 'NO_EFFECT',
      isAutoSlideshow: false,
      itemBorderColor: {
        value: '#292929',
      },
      itemBorderWidth: 0,
      magicLayoutSeed: 1,
      scrollAnimation: 'FADE_IN',
      scrollDirection: 0,
      useCustomButton: false,
      allowDescription: false,
      allowTitleExpand: true,
      bottomInfoHeight: 0,
      customButtonText: 'Buy Now',
      galleryTextAlign: 'center',
      imageLoadingMode: 'BLUR',
      itemBorderRadius: 0,
      itemEnableShadow: false,
      overlayAnimation: 'FADE_IN',
      textBoxFillColor: {
        value: '#FFFFFF',
      },
      watermarkOpacity: 40,
      hoveringBehaviour: 'APPEARS',
      imageLoadingColor: {
        value: '#CCCCCC',
      },
      rotatingCubeRatio: '',
      slideshowInfoSize: 200,
      thumbnailSpacings: 4,
      expandInfoPosition: 0,
      galleryAlignExpand: 'left',
      loadMoreButtonText: '',
      rotatingGroupTypes: '',
      textBoxBorderColor: {
        value: '#292929',
      },
      textBoxBorderWidth: 0,
      addToCartButtonText: '',
      imageHoverAnimation: 'NO_EFFECT',
      itemShadowDirection: 135,
      textBoxBorderRadius: 0,
      addToCartBorderWidth: 1,
      enableInfiniteScroll: true,
      galleryVerticalAlign: 'center',
      numberOfImagesPerCol: 1,
      numberOfImagesPerRow: 3,
      textsVerticalPadding: 0,
      allowFullscreenExpand: true,
      allowSlideshowCounter: false,
      autoSlideshowInterval: 4,
      customButtonFontColor: {
        value: '#FFFFFF',
      },
      defaultShowInfoExpand: 1,
      selectedGalleryLayout: 'grid',
      allowDescriptionExpand: true,
      galleryHorizontalAlign: 'center',
      itemFontColorSlideshow: {
        value: '#292929',
      },
      itemIconColorSlideshow: {
        value: '#292929',
      },
      oneColorAnimationColor: {
        value: '#FFFFFF',
      },
      customButtonBorderWidth: 1,
      gallerySliderImageRatio: 1.7777777777777777,
      customButtonBorderRadius: 0,
      galleryImageRatioFromWix: 1,
      itemDescriptionFontColor: {
        value: '#FFFFFF',
      },
      itemShadowOpacityAndColor: {
        value: 'rgba(41, 41, 41, 0.2)',
      },
      loadMoreButtonBorderColor: {
        value: '#292929',
      },
      loadMoreButtonBorderWidth: 1,
      calculateTextBoxHeightMode: 'AUTOMATIC',
      galleryThumbnailsAlignment: 'bottom',
      loadMoreButtonBorderRadius: 0,
      playButtonForAutoSlideShow: false,
      customButtonFontColorForHover: {
        value: '#292929',
      },
      useRefactoredGalleryContainer: false,
      itemDescriptionFontColorSlideshow: {
        value: '#292929',
      },
    },
    allowDisplay: true,
    defaultSetId: '3bffad92-1ed6-4468-81e3-8be61b84a549',
    displayBrand: true,
    isStoreAlbum: false,
    userFontSets: [],
    lastWatermark: {
      size: 66,
      opacity: 65,
      imageUrl: '60cca8_b2fa9c746b874b2581f9bde89d15f77b~mv2.png',
      position: 3,
      imageRatio: 1,
    },
    loginLanguage: 'en',
    motherSiteUrl: 'https://dorr42.wixsite.com/mysite-20',
    ulrModifiedAt: 1599573314773,
    selectedFontSet: 10,
    imageAddDirection: 'GALLERY_END',
    originalPublicUrl: 'https://dorr42.wixsite.com/tals-album',
    defaultNewItemName: 'FILE_NAME',
    isWatermarkEnabled: false,
    motherSiteMetaSiteId: '81f8f788-cd49-45b4-8806-b5e646cc72f5',
    shouldShowPhotoRightsNotification: false,
    coverUrl:
      'https://static.wixstatic.com/media/60cca8_5a335dbfc4104d47964635f7f684fd9a~mv2.jpg/v1/fill/w_805,h_1331,al_c,q_85,wm_8bb438_39a78b46d4fe47069da63da9348b4ee5~mv2.png-1-1-10/60cca8_5a335dbfc4104d47964635f7f684fd9a~mv2.jpg',
    freeArtStore: true,
    transparent:
      'https://static.wixstatic.com/media/60cca8_c6fad025c40a4acb840f458b3b6f5f5b~mv2.png/v1/fit/w_297,h_206/60cca8_c6fad025c40a4acb840f458b3b6f5f5b~mv2.png',
  },
  sets: [
    {
      id: '364e02b5-2729-4629-971c-13b4c2def85d',
      name: 'Gallery',
      totalItemsCount: 28,
      items: [
        {
          type: 'image',
          slug: '16fca6db-91a1-4424-986d-23e2d6e21025',
          title: 'bailey-zindel-NRQV-hBF10M-unsplash',
          src: 'wix:image://v1/60cca8_e7df637b43594bd3b549a320f4038036~mv2.jpg/bailey-zindel-nrqv-hbf10m-unsplash.jpg#originWidth=7360&originHeight=4912',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: 'e13eed45-bde9-4fa1-86ef-b27ac236ff23',
          title: 'casey-horner-_4XcZQ-2G5Q-unsplash',
          src: 'wix:image://v1/60cca8_d2f1168c42064d65af3fa58f9632293f~mv2.jpg/casey-horner-_4xczq-2g5q-unsplash.jpg#originWidth=4016&originHeight=6016',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: '311ee41b-891e-4592-9cfc-69cf13e09892',
          title: 'claudio-testa--SO3JtE3gZo-unsplash',
          src: 'wix:image://v1/60cca8_4af1a2b5e99b417584166be1511ab3f0~mv2.jpg/claudio-testa-so3jte3gzo-unsplash.jpg#originWidth=5184&originHeight=3240',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: 'b41cb979-25e1-4b2f-aaaf-8b2b3f558b25',
          title: 'daniela-cuevas-t7YycgAoVSw-unsplash',
          src: 'wix:image://v1/60cca8_d3aa22233d034532bee83fb244bc7ae8~mv2.jpg/daniela-cuevas-t7yycgaovsw-unsplash.jpg#originWidth=5760&originHeight=3840',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: '428ebddb-8844-4c1f-895d-4719f5fb2da4',
          title: 'jeff-king-bJHWJeiHfHc-unsplash',
          src: 'wix:image://v1/60cca8_c93c3b05e81b4354aa38719494a9da49~mv2.jpg/jeff-king-bjhwjeihfhc-unsplash.jpg#originWidth=3827&originHeight=2546',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: '7165c4a6-0468-4281-855c-3ffcd97977fc',
          title: 'johannes-plenio-RwHv7LgeC7s-unsplash',
          src: 'wix:image://v1/60cca8_15009a8e34eb44a2bb36a42b84cb2428~mv2.jpg/johannes-plenio-rwhv7lgec7s-unsplash.jpg#originWidth=4368&originHeight=2912',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: '2cc2f168-c2ea-4299-964e-ee948d8553a6',
          title: 'jonatan-pie-P2vi01BW8vE-unsplash',
          src: 'wix:image://v1/60cca8_72bf7a495eec46f78826a7dc274d6896~mv2.jpg/jonatan-pie-p2vi01bw8ve-unsplash.jpg#originWidth=4912&originHeight=7360',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: '51ca5622-9360-4b62-8392-fd1daa351763',
          title: 'ken-cheung-KonWFWUaAuk-unsplash',
          src: 'wix:image://v1/60cca8_5e8ae05b5a33420f9bdfdce86106bff5~mv2.jpg/ken-cheung-konwfwuaauk-unsplash.jpg#originWidth=5128&originHeight=3419',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: 'fee0ed83-88de-4cb7-9fa9-9faff7e50547',
          title: 'luca-bravo-zAjdgNXsMeg-unsplash',
          src: 'wix:image://v1/60cca8_5b17c111bf714b85ab026e30b43487cd~mv2.jpg/luca-bravo-zajdgnxsmeg-unsplash.jpg#originWidth=4513&originHeight=3009',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: '80768af9-6690-4234-b1d9-f876eb408a64',
          title: 'ryan-schroeder-Gg7uKdHFb_c-unsplash',
          src: 'wix:image://v1/60cca8_d5069a0ecff44913b00bec19c0eb5679~mv2.jpg/ryan-schroeder-gg7ukdhfb_c-unsplash.jpg#originWidth=4442&originHeight=2961',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: '79fd4b6d-255c-470e-8e86-392704351e94',
          title: 'simon-berger-twukN12EN7c-unsplash',
          src: 'wix:image://v1/60cca8_bd8e3c0d905045d9ac078d00069092f7~mv2.jpg/simon-berger-twukn12en7c-unsplash.jpg#originWidth=4608&originHeight=2963',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: 'b5578b72-2c26-4066-ac86-9377a6ec3fd7',
          title: 'pietro-de-grandi-T7K4aEPoGGk-unsplash',
          src: 'wix:image://v1/60cca8_7d6f31e0a5214e93af8cba42e3dd66fb~mv2.jpg/pietro-de-grandi-t7k4aepoggk-unsplash.jpg#originWidth=5979&originHeight=3986',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: '73c1e365-24bd-4926-8bcb-721690fe1d33',
          title: 'mark-harpur-K2s_YE031CA-unsplash',
          src: 'wix:image://v1/60cca8_f3c22430c33545a8a54dc93897bdcbd1~mv2.jpg/mark-harpur-k2s_ye031ca-unsplash.jpg#originWidth=5112&originHeight=3408',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: 'a70a9f29-2d2e-4c2e-993a-524a7f78f9e4',
          title: 'ales-krivec-4miBe6zg5r0-unsplash',
          src: 'wix:image://v1/60cca8_82995bc4608d474881d64865f826ee85~mv2.jpg/ales-krivec-4mibe6zg5r0-unsplash.jpg#originWidth=7360&originHeight=4100',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: 'ba6a2f1f-ec1f-4201-810a-147653ed922c',
          title: 'bailey-zindel-NRQV-hBF10M-unsplash',
          src: 'wix:image://v1/60cca8_62c3a35807464e34986ca83fcb9d5cb1~mv2.jpg/bailey-zindel-nrqv-hbf10m-unsplash.jpg#originWidth=7360&originHeight=4912',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: '69ae315f-e179-4eb7-a71b-83b7b92383bf',
          title: 'casey-horner-_4XcZQ-2G5Q-unsplash',
          src: 'wix:image://v1/60cca8_38c2932f68c04052a39eb0a762912e12~mv2.jpg/casey-horner-_4xczq-2g5q-unsplash.jpg#originWidth=4016&originHeight=6016',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: '0acc8bd5-c76e-4843-a41d-6dfab6fda825',
          title: 'claudio-testa--SO3JtE3gZo-unsplash',
          src: 'wix:image://v1/60cca8_50ccce9b00c04db884e4dcacca9a3cd5~mv2.jpg/claudio-testa-so3jte3gzo-unsplash.jpg#originWidth=5184&originHeight=3240',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: '69358046-9e48-401b-af43-27cac8b83a3b',
          title: 'daniela-cuevas-t7YycgAoVSw-unsplash',
          src: 'wix:image://v1/60cca8_1b0d610e6b4748198c7c7e2ed6a6773e~mv2.jpg/daniela-cuevas-t7yycgaovsw-unsplash.jpg#originWidth=5760&originHeight=3840',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: 'b5512711-2b7e-41d3-bca6-3a03bdedb845',
          title: 'jeff-king-bJHWJeiHfHc-unsplash',
          src: 'wix:image://v1/60cca8_a0c1d414478547eaa14038ec758f9213~mv2.jpg/jeff-king-bjhwjeihfhc-unsplash.jpg#originWidth=3827&originHeight=2546',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: '7060e00c-a471-49a5-9588-bbcf000d127b',
          title: 'johannes-plenio-RwHv7LgeC7s-unsplash',
          src: 'wix:image://v1/60cca8_4f4a2ebb79e4472c9a45dd9dc0a152ce~mv2.jpg/johannes-plenio-rwhv7lgec7s-unsplash.jpg#originWidth=4368&originHeight=2912',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: '888818e3-3860-4d05-84ec-b0cc5b380ca5',
          title: 'jonatan-pie-P2vi01BW8vE-unsplash',
          src: 'wix:image://v1/60cca8_eb0aac5a89f04accbdbe96cf7f6f5ee9~mv2.jpg/jonatan-pie-p2vi01bw8ve-unsplash.jpg#originWidth=4912&originHeight=7360',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: '92f20ffe-e2f3-4993-b4fb-896b4bc0d986',
          title: 'ken-cheung-KonWFWUaAuk-unsplash',
          src: 'wix:image://v1/60cca8_c5f4f93ec30c4312a736cca5336459a3~mv2.jpg/ken-cheung-konwfwuaauk-unsplash.jpg#originWidth=5128&originHeight=3419',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: 'e3222713-24ba-46bb-81f6-5669b0d2d597',
          title: 'luca-bravo-zAjdgNXsMeg-unsplash',
          src: 'wix:image://v1/60cca8_df22d4ea6b3443d9beecfcbc301e5d8a~mv2.jpg/luca-bravo-zajdgnxsmeg-unsplash.jpg#originWidth=4513&originHeight=3009',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: 'ede3c012-6cea-4fbe-8c61-bef8d8dc76a3',
          title: 'mark-harpur-K2s_YE031CA-unsplash',
          src: 'wix:image://v1/60cca8_71d7579d1a514e049ca461a78a9f63da~mv2.jpg/mark-harpur-k2s_ye031ca-unsplash.jpg#originWidth=5112&originHeight=3408',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: 'f8a8d76d-d05e-4649-b4ca-83b54f413f86',
          title: 'pietro-de-grandi-T7K4aEPoGGk-unsplash',
          src: 'wix:image://v1/60cca8_1c34725b6b914667bf7fd09dcd7f17cf~mv2.jpg/pietro-de-grandi-t7k4aepoggk-unsplash.jpg#originWidth=5979&originHeight=3986',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: 'cf7933a3-aba0-4d70-841e-0e2a47e12b07',
          title: 'ryan-schroeder-Gg7uKdHFb_c-unsplash',
          src: 'wix:image://v1/60cca8_2f9072acd0ad497ab2d7223fc72e337e~mv2.jpg/ryan-schroeder-gg7ukdhfb_c-unsplash.jpg#originWidth=4442&originHeight=2961',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: '7666f160-5fe7-4b8a-8c18-a67885628546',
          title: 'simon-berger-twukN12EN7c-unsplash',
          src: 'wix:image://v1/60cca8_927cb31990ec4e6192acc9235a4dc7d5~mv2.jpg/simon-berger-twukn12en7c-unsplash.jpg#originWidth=4608&originHeight=2963',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
      ],
      created: '2020-09-10T10:23:21Z',
      orderIndex: 1599733401056,
      nextGalleryName: 'Gallery 2',
      nextGalleryId: 'dcfed5e6-a3a1-4ea2-963c-f135d6458dd1',
      zipUrl:
        'https://www.wix.com/_api/albums-node-server/downloadZip?metaSiteId=81f8f788-cd49-45b4-8806-b5e646cc72f5&instanceId=ad2365fe-88c2-4ff3-94a1-75d4db2c2b96&state=PUBLISHED&setId=364e02b5-2729-4629-971c-13b4c2def85d',
    },
    {
      id: 'dcfed5e6-a3a1-4ea2-963c-f135d6458dd1',
      name: 'Gallery 2',
      totalItemsCount: 14,
      items: [
        {
          type: 'image',
          slug: 'e92d6b5b-98db-4444-8741-a09ac209754d',
          title: 'ales-krivec-4miBe6zg5r0-unsplash',
          src: 'wix:image://v1/60cca8_2133cb393a364c1eb09217610a8029af~mv2.jpg/ales-krivec-4mibe6zg5r0-unsplash.jpg#originWidth=7360&originHeight=4100',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: '383c7d0c-fcc0-45e4-b204-a6302f2f1bde',
          title: 'bailey-zindel-NRQV-hBF10M-unsplash',
          src: 'wix:image://v1/60cca8_d1bf7b11a1594b6fa756a6ce89ca3254~mv2.jpg/bailey-zindel-nrqv-hbf10m-unsplash.jpg#originWidth=7360&originHeight=4912',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: 'dd7fc027-38e3-40db-b594-a3190a50f1f7',
          title: 'casey-horner-_4XcZQ-2G5Q-unsplash',
          src: 'wix:image://v1/60cca8_ca7051417f54427db97b5ae0a0fc27f6~mv2.jpg/casey-horner-_4xczq-2g5q-unsplash.jpg#originWidth=4016&originHeight=6016',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: '08594863-85c0-4cca-9ab5-64feb3342fe7',
          title: 'claudio-testa--SO3JtE3gZo-unsplash',
          src: 'wix:image://v1/60cca8_5a6199180d6045d883e6aa163c13b7de~mv2.jpg/claudio-testa-so3jte3gzo-unsplash.jpg#originWidth=5184&originHeight=3240',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: 'e3dddcc4-3660-4f09-840c-f5ab27eef899',
          title: 'daniela-cuevas-t7YycgAoVSw-unsplash',
          src: 'wix:image://v1/60cca8_40de3f58e5364517b2e31ed8033c3a47~mv2.jpg/daniela-cuevas-t7yycgaovsw-unsplash.jpg#originWidth=5760&originHeight=3840',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: '9197fbd8-8418-48b5-944b-b58ab1b741a8',
          title: 'jeff-king-bJHWJeiHfHc-unsplash',
          src: 'wix:image://v1/60cca8_9c2eb5beaef64759b77eefe2148683c6~mv2.jpg/jeff-king-bjhwjeihfhc-unsplash.jpg#originWidth=3827&originHeight=2546',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: 'c974c37b-3fbc-4ff6-8024-23798fc1ebc1',
          title: 'johannes-plenio-RwHv7LgeC7s-unsplash',
          src: 'wix:image://v1/60cca8_318a91b40ed949038ceb1a4b3a7fa5c1~mv2.jpg/johannes-plenio-rwhv7lgec7s-unsplash.jpg#originWidth=4368&originHeight=2912',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: '68b6616e-f5ca-4716-b665-cf5add7d7cda',
          title: 'jonatan-pie-P2vi01BW8vE-unsplash',
          src: 'wix:image://v1/60cca8_54754c3e44d54b4085d909c60e49cc67~mv2.jpg/jonatan-pie-p2vi01bw8ve-unsplash.jpg#originWidth=4912&originHeight=7360',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: '6442dee5-46e3-4728-807a-49b0e93dfa46',
          title: 'ken-cheung-KonWFWUaAuk-unsplash',
          src: 'wix:image://v1/60cca8_243d69300a454ff49eb374d9a1d0e91c~mv2.jpg/ken-cheung-konwfwuaauk-unsplash.jpg#originWidth=5128&originHeight=3419',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: 'd06ae358-4b80-4082-962a-b7bd22d66dc9',
          title: 'luca-bravo-zAjdgNXsMeg-unsplash',
          src: 'wix:image://v1/60cca8_822df889632a44dba610137154b506a3~mv2.jpg/luca-bravo-zajdgnxsmeg-unsplash.jpg#originWidth=4513&originHeight=3009',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: '4a9c9fc1-9015-4452-b157-705844d83c03',
          title: 'mark-harpur-K2s_YE031CA-unsplash',
          src: 'wix:image://v1/60cca8_74e96b941429470a85add60826a16707~mv2.jpg/mark-harpur-k2s_ye031ca-unsplash.jpg#originWidth=5112&originHeight=3408',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: 'abb3236e-c147-4b3d-9de1-f788b01d6fb6',
          title: 'pietro-de-grandi-T7K4aEPoGGk-unsplash',
          src: 'wix:image://v1/60cca8_7c9936a9163c4ba580e012a0c73b102a~mv2.jpg/pietro-de-grandi-t7k4aepoggk-unsplash.jpg#originWidth=5979&originHeight=3986',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: '7d4aa2dc-fc4a-4965-8bcf-4b6be73b12c1',
          title: 'ryan-schroeder-Gg7uKdHFb_c-unsplash',
          src: 'wix:image://v1/60cca8_b885835c2e264810b53268dc97051bd9~mv2.jpg/ryan-schroeder-gg7ukdhfb_c-unsplash.jpg#originWidth=4442&originHeight=2961',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
        {
          type: 'image',
          slug: 'cf30a5e5-aeab-4a94-998a-d2128975ad60',
          title: 'simon-berger-twukN12EN7c-unsplash',
          src: 'wix:image://v1/60cca8_96dd6e6070574f43a9fa906e3d3e0414~mv2.jpg/simon-berger-twukn12en7c-unsplash.jpg#originWidth=4608&originHeight=2963',
          settings: {
            focalPoint: [0.5, 0.5],
          },
          defaultSetId: '364e02b5-2729-4629-971c-13b4c2def85d',
        },
      ],
      created: '2020-09-10T10:24:28Z',
      orderIndex: 1599733467643,
      zipUrl:
        'https://www.wix.com/_api/albums-node-server/downloadZip?metaSiteId=81f8f788-cd49-45b4-8806-b5e646cc72f5&instanceId=ad2365fe-88c2-4ff3-94a1-75d4db2c2b96&state=PUBLISHED&setId=dcfed5e6-a3a1-4ea2-963c-f135d6458dd1',
    },
  ],
};
