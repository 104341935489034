import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import {
  getWixCodeGalleryItems,
  getWixCodeAlbumData,
  setPageSeo,
} from '@wix/albums-uou-common';
import { getData } from '../../services/dataService';
import templatesData from '../../config/templatesData';
import {
  IWixAPI,
  IWidgetControllerConfig,
} from '@wix/native-components-infra/dist/src/types/types';
import { photoAlbumCoverLoaded } from '@wix/bi-logger-photo-ugc/v2';

let resultData: any = {};
let modifiedOn = new Date().getTime();
let errorFetchingDataOnSSr = false;

let refreshCallback = (resData: any) => {
  return resData;
};
const isTemplate = ({ baseUrl, query }: any) => {
  return (
    baseUrl.indexOf('exposure4.wixsite.com/website-') > -1 ||
    baseUrl.indexOf('wix.com/exposure4/website-') > -1 ||
    !!query.skipItems
  );
};

export const refreshSets = (data: any, options: any = {}) => {
  modifiedOn = new Date().getTime();
  if (data && data.albumData) {
    const albumData = data.albumData || resultData.albumData;
    const sets = data.sets || resultData.sets;
    resultData.sets = sets && getWixCodeGalleryItems(sets, albumData);
    resultData.albumData = getWixCodeAlbumData(
      albumData,
      resultData.sets,
      options,
    );
    refreshCallback(resultData);
  }
};

const trimUntilSlash = (str: string, index: number) =>
  str.split('/').slice(0, index).join('/');

export const getBaseUrl = (wixCodeApi: IWixAPI) => {
  const {
    location: { baseUrl },
  } = wixCodeApi;

  const baseUrlPartsLength = 3;

  return trimUntilSlash(baseUrl, baseUrlPartsLength);
};

const goToPage = (controllerConfig: IWidgetControllerConfig) => (
  path: string,
) => {
  try {
    if (controllerConfig.wixCodeApi.location.to) {
      controllerConfig.wixCodeApi.location.to(path);
    }
  } catch (e) {
    console.error('albums uou, error navigating', e);
  }
};

const createController: CreateControllerFn = async ({
  flowAPI,
  controllerConfig,
}) => {
  let resultsPromise: Promise<any>;
  const {
    setProps,
    wixCodeApi,
    appParams: { baseUrls, instance, instanceId },
    platformAPIs: { bi },
  } = controllerConfig;
  const {
    window: { viewMode, warmupData },
  } = wixCodeApi;
  let windowRect = { width: 0, height: 0 };
  try {
    // @ts-expect-error
    if (wixCodeApi && wixCodeApi.window && wixCodeApi.window.getBoundingRect) {
      // @ts-expect-error
      const rectResult = (await wixCodeApi.window.getBoundingRect()) || {};
      windowRect = rectResult.window;
    }
  } catch (e) {}
  const { albumData: templateAlbumData, sets: templateSets } = templatesData;
  const { width = 0, height = 0 } = windowRect || {};
  const coverMaxSize = Math.max(width, height);
  const isPreview = viewMode === 'Preview';
  const state = isPreview ? 'SAVED' : 'PUBLISHED';
  const {
    experiments,
    environment: { isMobile, multilingual, isViewer, isSSR },
    fedops: { appLoaded },
    httpClient,
    bi: biLogger,
  } = flowAPI;
  if (isViewer) {
    biLogger?.report(
      photoAlbumCoverLoaded({
        biToken: bi?.metaSiteId,
        instance_id: instanceId,
        visitor_id: bi?.visitorId,
      }),
    );
  }
  const _refrestSets = (data: any) =>
    refreshSets(data, { coverMaxSize, isMobile });
  const manageWarmupData = async (callback: any, key: string) => {
    let res;
    if (!isSSR) {
      res = await warmupData.get(key);
    }
    if (!res) {
      res = callback();
      if (isSSR) {
        res.then((result: any) => {
          warmupData.set(key, result);
        });
      }
    }
    return res;
  };

  const fetchAlbumData = () => {
    const data = getData(
      httpClient,
        //@ts-ignore
      getBaseUrl(wixCodeApi),
      instanceId,
      state,
      {
        coverMaxSize,
        isMobile,
      },
      isPreview,
      wixCodeApi.location,
      // useSSrPath,
    ).then((result) => {
      resultData = result;
      errorFetchingDataOnSSr = result.albumData.isSSrGetAlbumDataFailed;
      try {
        const coverUrl = result.coverUrl;
        const { sets, albumData } = result;
        setPageSeo({ wixCodeApi, coverUrl, sets, albumData });
        // SHOULD BE FIXED BY SETTING HOMA PEGA WITH DS
        if (
          sets?.length === 1 &&
          albumData.template.coverPageName === 'sets' &&
          albumData.createdOnServer &&
          wixCodeApi.location.path.length === 0 &&
          wixCodeApi.location.to
        ) {
          albumData.albumName = '';
          albumData.gogItems = [];
          wixCodeApi.location.to('/set/' + sets[0].id);
        }
      } catch (e) {
        console.log('Failed to set title seo');
      }
      return resultData;
    });
    return data;
  };

  if (isTemplate(wixCodeApi.location)) {
    const wixCodeAlbumData = getWixCodeAlbumData(
      templateAlbumData,
      templateSets,
      {
        coverMaxSize,
      },
    );
    resultsPromise = Promise.resolve({
      albumData: wixCodeAlbumData,
      sets: templateSets,
    });
  } else {
    resultsPromise = experiments.enabled('specs.albums.warmupDataUOU')
      ? manageWarmupData(fetchAlbumData, 'albumData')
      : fetchAlbumData();
  }

  const shouldRefresh = (_modifiedOn: number) => {
    return {
      modifiedOn,
      changed: modifiedOn !== _modifiedOn,
    };
  };
  const onRefreshHappened = (callback: () => any) => {
    refreshCallback = callback;
  };
  const onReady = () => {
    return resultsPromise;
  };
  const mailToFunc = (siteUrl: string) => {
    setProps({
      shareUrl: { url: siteUrl },
    });
  };

  const reportPageLoaded = (setId: string) => {
    setProps({
      currentPage: setId || '',
      reportPageLoadedTriggered: new Date().getTime(),
      time: new Date().getTime(),
      name: 'World',
      cssBaseUrl: baseUrls.staticsBaseUrl,
      locale: multilingual?.currentLanguage,
      mobile: isMobile,
      experiments,
      instance,
      instanceId,
      refreshSets: _refrestSets,
      reportAppLoaded: appLoaded,
        //@ts-ignore
      goToPage: goToPage(controllerConfig),
    });
  };
  return {
    async pageReady() {
      setProps({
        name: 'World',
        time: new Date().getTime(),
        cssBaseUrl: baseUrls.staticsBaseUrl,
        locale: multilingual?.currentLanguage,
        mobile: isMobile,
        experiments,
        instance,
        instanceId,
        refreshSets: _refrestSets,
        //@ts-ignore
        goToPage: goToPage(controllerConfig),
        errorFetchingDataOnSSr,
      });
    },
    exports: () => ({
      shouldRefresh,
      onRefreshHappened,
      onReady,
      reportPageLoaded,
      mailToFunc,
    }),
  };
};

export default createController;
