
  import userController from '/home/builduser/work/cd0418694237b2be/packages/albums-uou-app/src/components/App/controller.ts';
  import createControllerWrapper from '@wix/yoshi-flow-editor/runtime/esm/pureControllerWrapper.js';

  
import wrapController from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/ooi';


  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
    DSN: 'https://5dea4716a90a4568954bc0c911cf236b@sentry.wixpress.com/2013',
    id: '5dea4716a90a4568954bc0c911cf236b',
    projectName: 'albums-uou-app',
    teamName: 'albums',
    errorMonitor: true,
  };

  var experimentsConfig = {"scopes":["albums"],"centralized":true};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/cd0418694237b2be/packages/albums-uou-app/src/assets/locales/messages_en.json","availableLanguages":["de","en"]};

  var biConfig = null;

  var defaultTranslations = {"app.hello":"Hello","ALBUM_SHARE_MAILTO_SUBJECT":"I wanted you to see this site","ALBUM_SHARE_MAILTO_BODY":"Check out this site%0D%0A {shareUrl}."};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/cd0418694237b2be/packages/albums-uou-app/target/generated/bi/createBILogger.ts';

  const controllerOptions = {
    sentryConfig,
    biConfig,
    fedopsConfig,
    experimentsConfig,
    biLogger,
    translationsConfig,
    persistentAcrossPages: false,
    appName: "albums-uou-app",
    componentName: "App",
    appDefinitionId: "d3e94e97-1218-4796-aa05-c9a6c68ecb16",
    componentId: "1078f2d8-ae0e-4dd1-a0ef-105c44b16066",
    projectName: "albums-uou-app",
    defaultTranslations,
    multilingualDisabled,
    shouldUseEssentials: true,
    withErrorBoundary: false,
    localeDistPath: "assets/locales"
  };

  const _controller = createControllerWrapper(userController, controllerOptions, {
    initI18n,
    createHttpClient,
    createExperiments,
  });

  export const wrap = wrapController;
  export const descriptor = {
    ...controllerOptions,
    id: controllerOptions.componentId,
    widgetType: "WIDGET_OUT_OF_IFRAME",
    controllerFileName: "/home/builduser/work/cd0418694237b2be/packages/albums-uou-app/src/components/App/controller.ts",
  };

  export const controller = _controller
  export default controller;
