export default {
  albumData: {
    id: 'ad2365fe-88c2-4ff3-94a1-75d4db2c2b96',
    brand: {
      logo: {
        name: '60cca8_4dc954ac18d84e45b3b30cf95735edb5~mv2.jpg',
        title: 'matt-lee-18767.jpg',
        mediaUrl: 's',
        photoMetadata: {
          width: 3888,
          height: 2592,
        },
      },
      name: '',
      type: 'NAME',
    },
    cover: {
      id: '4f48006e-1467-42eb-9594-05e673e6d4fc',
      name: '8bb438_4ec1bb2c64774f99876607dd105a90e3~mv2_d_3456_5184_s_4_2.jpg',
      title: 'casey-horner-_4XcZQ-2G5Q-unsplash',
      created: '2020-09-08T13:53:31.364Z',
      visible: true,
      dataType: 'Photo',
      isSecure: false,
      mediaUrl:
        '8bb438_4ec1bb2c64774f99876607dd105a90e3~mv2_d_3456_5184_s_4_2.jpg',
      modified: '2020-09-08T13:53:31.364Z',
      thumbnail: '',
      imageToken: '',
      mediaOwner: 'Wix',
      orderIndex: 1599573209657,
      photoMetadata: {
        width: 3456,
        height: 5184,
        focalPoint: {
          x: 0.5,
          y: 0.5,
        },
      },
    },
    colors: {
      pageTexts: '#FFFFFF',
      coverTexts: '#FFFFFF',
      galleriesTexts: '#FFFFFF',
      pageBackground: '#FFFFFF',
      coverBackground: '#FFFFFF',
      galleriesOverlay: '#FFFFFF',
    },
    folder: {
      id: '7f348936e8664e98b4cfdd09054a6203',
      name: 'Tals album',
    },
    layout: 'collage',
    siteId: 'b68f27c6-47da-447c-835e-b32d31061e78',
    userId: '60cca876-a865-457d-b852-96265a773c22',
    version: 2,
    welcome: '',
    allowSEO: true,
    siteName: 'tals-album',
    template: {
      name: 'classic2',
      title: 'Albums_Wizard_StylePage_Classic',
      colors: {
        pageTexts: '#FFFFFF',
        coverTexts: '#FFFFFF',
        galleriesTexts: '#FFFFFF',
        pageBackground: '#FFFFFF',
        coverBackground: '#FFFFFF',
        galleriesOverlay: '#FFFFFF',
      },
      siteId: '07994d96-7ad9-48d2-9a28-f6ffd8b51e5d',
      version: 3,
      metasiteId: '93f87473-1865-4405-a7da-de2b6c6053bd',
      selectedFontSet: 10,
      coverIsBackground: true,
    },
    albumDate: '',
    albumName: '',
    albumPass: '',
    homeSetId: '9c4b2cdd-9a60-4aa8-950c-44f953b53136',
    publicUrl: 'https://dorr42.wixsite.com/tals-album',
    watermark: {
      size: 10,
      opacity: 1,
      imageUrl: '8bb438_39a78b46d4fe47069da63da9348b4ee5~mv2.png',
      position: 1,
      imageRatio: 1,
    },
    accessType: 'PUBLIC',
    metaSiteId: '69603c14-18b3-4543-9f7c-0d0c6293a321',
    modifiedAt: 1599829649357,
    dateCreated: 1599573208762,
    displayDate: true,
    pricelistId: 'da5cb7c0-59cf-45c9-90c0-4862e2d51622',
    setsSummary: {
      '3bffad92-1ed6-4468-81e3-8be61b84a549': {
        id: '3bffad92-1ed6-4468-81e3-8be61b84a549',
        name: 'Gallery',
        items: [],
        orderIndex: 1599573209104,
      },
      'd23a49cd-aafa-4481-9e4b-49b6e071be68': {
        id: 'd23a49cd-aafa-4481-9e4b-49b6e071be68',
        name: 'Gallery 3',
        items: [],
        orderIndex: 1599573528753,
      },
      'f1e97c3b-9d42-47a9-8c3a-6411c295eaa1': {
        id: 'f1e97c3b-9d42-47a9-8c3a-6411c295eaa1',
        name: 'Gallery 2',
        items: [],
        orderIndex: 1599573255302,
      },
    },
    styleParams: {
      usm_a: 0,
      usm_r: 0,
      usm_t: 0,
      isGrid: true,
      oneRow: false,
      cubeType: 'fill',
      isSlider: false,
      viewMode: 'preview',
      boxShadow: 0,
      cubeRatio: 1,
      gridStyle: 1,
      groupSize: 1,
      isColumns: false,
      isMasonry: false,
      itemClick: 'expand',
      smartCrop: false,
      usmToggle: false,
      videoLoop: true,
      videoPlay: 'hover',
      allowHover: true,
      allowTitle: false,
      cubeImages: true,
      fullscreen: true,
      groupTypes: '1',
      isVertical: true,
      loveButton: true,
      showArrows: false,
      videoSound: false,
      videoSpeed: '1',
      allowSocial: false,
      arrowsColor: {
        value: '#FFFFFF',
      },
      gallerySize: 70,
      galleryType: 'Columns',
      imageMargin: 30,
      imageResize: 0,
      isSlideshow: false,
      itemOpacity: {
        value: 'rgb(255, 255, 255)',
      },
      loveCounter: true,
      minItemSize: 50,
      sharpParams: {
        usm: {},
        quality: 90,
      },
      borderRadius: 0,
      cropOnlyFill: false,
      enableScroll: true,
      fixedColumns: 0,
      imageQuality: 90,
      overlayColor: {
        value: '#FFFFFF',
      },
      previewHover: false,
      useWatermark: true,
      allowDownload: false,
      galleryLayout: 2,
      hasThumbnails: false,
      imageInfoType: 'NO_BACKGROUND',
      itemFontColor: {
        value: '#FFFFFF',
      },
      itemIconColor: {
        value: '#FFFFFF',
      },
      slideshowLoop: false,
      textBoxHeight: 200,
      thumbnailSize: 120,
      watermarkDock: {
        top: 'auto',
        left: 'auto',
        right: 0,
        bottom: 0,
        transform: 'translate3d(0,0,0)',
      },
      watermarkSize: 40,
      arrowsPosition: 0,
      collageDensity: 0.4,
      floatingImages: 0,
      fullscreenLoop: false,
      gotStyleParams: true,
      groupsPerStrip: 0,
      itemShadowBlur: 20,
      itemShadowSize: 10,
      loadMoreAmount: 'all',
      mobilePanorama: false,
      overlayOpacity: 0,
      placeGroupsLtr: true,
      textImageSpace: 10,
      titlePlacement: 'SHOW_ON_HOVER',
      allowLinkExpand: false,
      alwaysShowHover: false,
      chooseBestGroup: true,
      expandAnimation: 'NO_EFFECT',
      isAutoSlideshow: false,
      itemBorderColor: {
        value: '#FFFFFF',
      },
      itemBorderWidth: 0,
      magicLayoutSeed: 1,
      scrollAnimation: 'FADE_IN',
      scrollDirection: 0,
      useCustomButton: false,
      allowDescription: false,
      allowTitleExpand: true,
      bottomInfoHeight: 0,
      customButtonText: 'Buy Now',
      galleryTextAlign: 'center',
      imageLoadingMode: 'BLUR',
      itemBorderRadius: 0,
      itemEnableShadow: false,
      overlayAnimation: 'FADE_IN',
      textBoxFillColor: {
        value: '#FFFFFF',
      },
      watermarkOpacity: 40,
      hoveringBehaviour: 'APPEARS',
      imageLoadingColor: {
        value: '#FFFFFF',
      },
      rotatingCubeRatio: '',
      slideshowInfoSize: 200,
      thumbnailSpacings: 4,
      expandInfoPosition: 0,
      galleryAlignExpand: 'left',
      loadMoreButtonText: '',
      rotatingGroupTypes: '',
      textBoxBorderColor: {
        value: '#FFFFFF',
      },
      textBoxBorderWidth: 0,
      addToCartButtonText: '',
      imageHoverAnimation: 'NO_EFFECT',
      itemShadowDirection: 135,
      textBoxBorderRadius: 0,
      addToCartBorderWidth: 1,
      enableInfiniteScroll: true,
      galleryVerticalAlign: 'center',
      numberOfImagesPerCol: 1,
      numberOfImagesPerRow: 3,
      textsVerticalPadding: 0,
      allowFullscreenExpand: true,
      allowSlideshowCounter: false,
      autoSlideshowInterval: 4,
      customButtonFontColor: {
        value: '#FFFFFF',
      },
      defaultShowInfoExpand: 1,
      selectedGalleryLayout: 'grid',
      allowDescriptionExpand: true,
      galleryHorizontalAlign: 'center',
      itemFontColorSlideshow: {
        value: '#FFFFFF',
      },
      itemIconColorSlideshow: {
        value: '#FFFFFF',
      },
      oneColorAnimationColor: {
        value: '#FFFFFF',
      },
      customButtonBorderWidth: 1,
      gallerySliderImageRatio: 1.7777777777777777,
      customButtonBorderRadius: 0,
      galleryImageRatioFromWix: 1,
      itemDescriptionFontColor: {
        value: '#FFFFFF',
      },
      itemShadowOpacityAndColor: {
        value: 'rgb(255, 255, 255)',
      },
      loadMoreButtonBorderColor: {
        value: '#FFFFFF',
      },
      loadMoreButtonBorderWidth: 1,
      calculateTextBoxHeightMode: 'AUTOMATIC',
      galleryThumbnailsAlignment: 'bottom',
      loadMoreButtonBorderRadius: 0,
      playButtonForAutoSlideShow: false,
      customButtonFontColorForHover: {
        value: '#FFFFFF',
      },
      useRefactoredGalleryContainer: false,
      itemDescriptionFontColorSlideshow: {
        value: '#FFFFFF',
      },
    },
    allowDisplay: true,
    defaultSetId: '3bffad92-1ed6-4468-81e3-8be61b84a549',
    displayBrand: true,
    isStoreAlbum: false,
    userFontSets: [],
    lastWatermark: {
      size: 66,
      opacity: 65,
      imageUrl: '60cca8_b2fa9c746b874b2581f9bde89d15f77b~mv2.png',
      position: 3,
      imageRatio: 1,
    },
    loginLanguage: 'en',
    motherSiteUrl: 'https://dorr42.wixsite.com/mysite-20',
    ulrModifiedAt: 1599573314773,
    selectedFontSet: 10,
    imageAddDirection: 'GALLERY_END',
    originalPublicUrl: 'https://dorr42.wixsite.com/tals-album',
    defaultNewItemName: 'FILE_NAME',
    isWatermarkEnabled: false,
    motherSiteMetaSiteId: '81f8f788-cd49-45b4-8806-b5e646cc72f5',
    shouldShowPhotoRightsNotification: false,
    coverUrl:
      'https://static.wixstatic.com/media/85e5b4_07862cce27bf4ddf854313e502008157~mv2.jpeg/v1/fit/w_888,h_1358,q_90/85e5b4_07862cce27bf4ddf854313e502008157~mv2.webp',
    freeArtStore: true,
    transparent:
      'https://static.wixstatic.com/media/60cca8_c6fad025c40a4acb840f458b3b6f5f5b~mv2.png/v1/fit/w_297,h_206/60cca8_c6fad025c40a4acb840f458b3b6f5f5b~mv2.png',
    gogItems: [],
    coverOptions: {},
    coverItems: [
      {
        description: '',
        src: 'https://static.wixstatic.com/media/a9ff3b_a268304dd2124c829801c489448477a0~mv2.png/v1/fill/w_104,h_48,al_c/a9ff3b_a268304dd2124c829801c489448477a0~mv2.png',
        title: '',
        type: 'image',
      },
    ],
    isSSrGetAlbumDataFailed: true,
  },
  sets: [
  ],
  coverUrl: 'https://static.wixstatic.com/media/a9ff3b_a268304dd2124c829801c489448477a0~mv2.png/v1/fill/w_104,h_48,al_c/a9ff3b_a268304dd2124c829801c489448477a0~mv2.png',
};
