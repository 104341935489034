import templatesData from '../config/templatesData';
import ssrErrorTemplatesData from '../config/ssrErrorTemplatesData';
import { get } from 'lodash';
import imageClientApiService from './imageClientService';
import {
  getWixCodeAlbumData,
  getWixCodeGalleryItems,
} from '@wix/albums-uou-common';
import { GALLERY_TYPES } from '../consts/general';

function getCoverUrl(albumData, sets, firstItem) {
  let cover = albumData.cover;
  if (!cover && firstItem) {
    firstItem.name =
      sets && sets[0]?.items.find((x) => x.slug === firstItem.id)?.name;
    firstItem.isSecure = true;
    cover = firstItem;
  }
  if (!cover) {
    cover = templatesData.albumData.cover;
  }

  const coverUrl = imageClientApiService.getCoverUrl(albumData, {
    cover,
    maxSize: 500,
  });
  return coverUrl;
}

export const getData = (
  httpClient,
  baseUrl,
  instanceId,
  state,
  options,
  isPreview,
  location,
  // isSSR,
) => {
  return new Promise(async (resolve) => {
    const isSetsPage =
      location.path.length > 0 &&
      location.path[0].toLowerCase().includes(GALLERY_TYPES.SETS);

    const endpointName = (isSetsPage && 'getAlbumSets') || 'getAlbum';
    let url;
    if (isPreview || !baseUrl) {
      url = `https://www.wix.com/_api/albums-node-server/${endpointName}?state=${state}&instanceId=${instanceId}&setItemsLimit=''`;
    } else {
      url = `${baseUrl.replace(
        'http://',
        'https://',
      )}/albums-node-server/getAlbum?state=${state}&instanceId=${instanceId}&setItemsLimit=''`;
    }

    // if (isSSR) {
    //   url = `http://api.42.wixprod.net/albums-node-server/api/getAlbum?state=${state}&instanceId=${instanceId}&setItemsLimit=''`;
    // }

    console.log('URL for getAlbum', url);
    try {
      const getAlbumResult = await httpClient.get(url);
      let albumData = get(getAlbumResult, 'data.settings');
      const albumGalleriesServerItems = get(
        getAlbumResult,
        'data.sets.gallery',
      );
      const firstItem = get(albumGalleriesServerItems, '[0].items[0]');

      const sets = getWixCodeGalleryItems(albumGalleriesServerItems, albumData);
      const coverUrl = getCoverUrl(albumData, sets, firstItem);
      albumData = getWixCodeAlbumData(albumData, sets, options, location);
      resolve({ albumData, sets, coverUrl });
    } catch (e) {
      console.log('Base url for fetching + url', { baseUrl, url });
      console.error('Error fetching albums data uou', e);
      resolve(ssrErrorTemplatesData);
    }
  });
};
